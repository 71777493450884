import { getQueryParamsFromUrl } from '@helpers/miscelleanous';
import { getAuth } from '@services/identity.service';
import { useEffect } from 'react'

const useHomepageActivities = ({
    auth,
    setIsLoggedIn,
    router,
    setReferralCode,
    setReferralEntityType,
    supportsIntersectionObserver,
    setShowLogin
}) => {
    useEffect(() => {
        const auth = getAuth();
        if (auth) {
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
        }
    }, [auth]);

    useEffect(() => {
        (async () => {
            const referralCode =
                router.query?.referrer ||
                router.query?.usoc ||
                getQueryParamsFromUrl(window.location.search, 'usoc') ||
                getQueryParamsFromUrl(window.location.search, 'referrer');

            if (referralCode) {
                if (
                    router.query?.usoc ||
                    getQueryParamsFromUrl(window.location.search, 'usoc')
                ) {
                    setReferralEntityType('usoc')
                } else {
                    setReferralEntityType('referrer')
                }
                setReferralCode(referralCode);
            }
        })();
    }, []);

    useEffect(() => {
        if (supportsIntersectionObserver()) {
            const callback = function (entries) {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const animationClass = entry.target.dataset.animation;
                        if (animationClass) {
                            entry.target.classList.add(
                                ...['animate__animated', animationClass]
                            );
                        }
                    }
                });
            };

            let observer = new IntersectionObserver(callback);

            const targets = document.querySelectorAll('[data-animation]');
            targets.forEach(function (target) {
                target.classList.add('animatable');
                observer.observe(target);
            });
            return () => {
                targets.forEach(function (target) {
                    observer.unobserve(target);
                });
                observer = null;
            };
        }
    }, []);

    useEffect(() => {
        // if redirect and deeplink both are present in the query then redirecting user to the provided url
        let redirect = router?.query?.redirect || '';
        let deeplink = router?.query?.deeplink || '';
        if (auth && redirect && deeplink) {
            router.replace(redirect);
        } else if (redirect && deeplink) {
            setShowLogin(true);
        }
    }, [router?.query]);

    return {};
}

export default useHomepageActivities;
